<template>
  <div class="radio-group">
    <button
      v-for="(item, index) in items"
      :key="`radio_${index}`"
      class="radio-group__button text-base"
      :class="[
        isActive(item) && 'radio-group__button--selected bold text-gray-950',
        (item.disabled || disabled) && 'radio-group__button--disabled'
      ]"
      :disabled="disabled"
      @click.prevent="$emit('update:modelValue', item)"
    >
      <span class="radio-group__circle" />
      <slot :item="item">{{ item[itemText] }}</slot>
    </button>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    items: {
      type: Array as PropType<any[]>,
      required: true
    },
    modelValue: {
      type: Object as PropType<any>,
      required: false,
      default: null
    },
    itemValue: {
      type: String,
      required: false,
      default: 'id'
    },
    itemText: {
      type: String,
      required: false,
      default: 'text'
    },
    disabled: Boolean
  },

  emits: ['update:modelValue'],

  setup(props) {
    const isActive = (item: any) => {
      return Object.prototype.hasOwnProperty.call(item, props.itemValue)
        ? item[props.itemValue] === props.modelValue[props.itemValue]
        : item === props.modelValue
    }

    return {
      isActive
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-group {
  .radio-group__button {
    display: block;
    position: relative;
    padding-left: 20px;
    color: $grey--darkest;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &.radio-group__button--selected {
      .radio-group__circle::after {
        visibility: visible;
      }
    }
  }
  .radio-group__circle {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: rem(4);
    border: 1px solid $grey--dark;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    box-shadow: 0 0 3px 1px rgba(33, 33, 33, 0.2) inset;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      background: $blue;
      visibility: hidden;
      transform: scale(0.8);
      transition: 0.3s cubic-bezier(0.19, 1, 0.56, 1);
    }
  }
}
.radioButton {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: rem(8);
  padding: rem(16) rem(48) rem(16) rem(16);
  width: 100%;
  min-height: 60px;
  border: 1px solid $grey--light;
  border-radius: 4px;
  font-family: $mb-font-text;
  font-size: rem(16);
  text-align: left;
  color: $black--light;
  cursor: pointer;
  transition: 0.2s linear;
  -webkit-tap-highlight-color: transparent;

  &--selected {
    border: 1px solid $blue;
    cursor: default;

    .radioButton__circle {
      &::after {
        transform: scale(1);
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &--disabled {
    opacity: 0.3;
    cursor: unset;
  }
}
</style>
